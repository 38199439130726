export enum AnalyticsEvents {
  SIGN_UP_STARTED = 'Sign Up started',
  SIGN_UP_COMPLETED = 'Sign Up completed',
  APPSUMO_SIGN_UP_STARTED = 'AppSumo Sign Up started',
  APPSUMO_SIGN_UP_COMPLETED = 'AppSumo Sign Up completed',
  GMAIL_SIGN_UP_COMPLETED = 'Gmail Sign Up completed',
  EMAIL_VERIFIED = 'Email verified',
  ONBOARDING_USAGE_PLAN = 'Onboarding usage plan updated',
  ONBOARDING_TEAM_CREATED = 'Onboarding team created',
  ONBOARDING_INVITATION_SENT = 'Onboarding invitation(s) sent',
  INVITE_TEAMMATES_STEP_SKIPPED = 'Invite teammates step skipped',
  ONBOARDING_PAYWALL_SHOWED = 'Onboarding paywall showed',
  TEAM_CREATED = 'Team created',
  TEAM_DELETED = 'Team deleted',
  PAGE_CREATED_FROM_SCRATCH = 'Page created from scratch',
  PAGE_CREATED_FROM_TEMPLATE = 'Page created from template',
  PAGE_CREATED_WITH_AI = 'Page created with AI',
  TAB_GENERATED_WITH_AI = 'Tab generated with AI',
  PAGE_PUBLISHED = 'Page published',
  PAGE_UNPUBLISHED = 'Page unpublished',
  PAGE_UPDATE_PUBLISHED = 'Page update published',
  ALERT_BAR_TOOL_ACTIVATED = 'Alert Bar tool activated',
  POP_UP_ACTIVATED = 'Pop-up activated',
  CTA_ACTIVATED = 'CTA activated',
  GATED_CONTENT_ACTIVATED = 'Gated Content activated',
  SQUEEZE_PAGE_ACTIVATED = 'Squeeze Page activated',
  PAGE_SAVED_AS_TEMPLATE = 'Page saved as template',
  AI_STARTED = 'AI started',
  PAGE_ANALYTIC_OPENED = 'Page Analytic opened',
  PAGE_LEADS_OPENED = 'Page Leads opened',
  PAGE_RECENT_VIEWERS_OPENED = 'Page Recent viewers opened',
  LEADS_LIST_EXPORTED = 'Leads list exported',
  ANALYTICS_RESET = 'Analytics reset',
  SUBSCRIPTION_ADJUSTED = 'Subscription Adjusted',
  TRIAL_STARTED = 'Trial started',
  TRIAL_CONVERTED = 'Trial converted',
  TRIAL_CANCELLED = 'Trial cancelled',
  PAID_SUBSCRIPTION_STARTED = 'Paid Subscription started',
  PAID_SUBSCRIPTION_CANCELLED = 'Paid Subscription Cancelled',
  PAID_SUBSCRIPTION_RENEWED = 'Paid Subscription Renewed',
  PAYMENT_SUCCEEDED = 'Payment Succeeded',
  PAYMENT_FAILED = 'Payment Failed',
  TEMPLATE_IS_CREATED = 'Template has been created',
  EXTENSION_INSTALLED = 'Extension installed',
  EXTENSION_INSTALL_CLICK = 'Extension install click',
  CALL_SUMMARY_COPIED = 'Call summary copied',
  CALL_TRANSCRIPT_COPIED = 'Call transcript copied',
  CALL_RECORDED = 'Call recorded',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventProperties = Record<string, any>;
