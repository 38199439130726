import { Extension } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import { Plugin, PluginKey } from 'prosemirror-state';
import { v4 as uuidv4 } from 'uuid';
import { Slice, Fragment } from '@tiptap/pm/model';

const unsupportedNodeTypes = [
  'text',
  'doc',
  'textStyle',
  'bold',
  'italic',
  'strike',
  'underline',
  'highlight',
];

export const NodeID = Extension.create({
  name: 'nodeID',

  addGlobalAttributes() {
    const nodeTypes = this.extensions
      .map((ext) => ext.name)
      .filter(filterUnsupportedNodes);

    return [
      {
        types: nodeTypes,
        attributes: {
          id: {
            default: null,
            parseHTML: (element) => element.getAttribute('data-id'),
            renderHTML: (attributes) => {
              if (!attributes.id) {
                return {};
              }

              return { 'data-id': attributes.id };
            },
          },
        },
      },
    ];
  },

  addProseMirrorPlugins() {
    let previousNodeIds = new Map();

    const nodeTypes = Object.keys(this.editor.schema.nodes).filter(
      filterUnsupportedNodes
    );

    return [
      new Plugin({
        key: new PluginKey('nodeID'),

        // When editor initializes, generate IDs for all nodes if needed
        view(view) {
          const tr = view.state.tr;
          let requiresUpdate = false;

          const seenIds = new Set<string>();

          view.state.doc.descendants((node, pos) => {
            if (!nodeTypes.includes(node.type.name)) return;

            const currentId = node.attrs.id;
            const isDuplicate = seenIds.has(node.attrs.id);

            if (!currentId || isDuplicate) {
              const newId = generateUniqueId(seenIds);
              tr.setNodeMarkup(pos, null, { ...node.attrs, id: newId });
              seenIds.add(newId);
              previousNodeIds.set(pos, newId);
              requiresUpdate = true;
            } else {
              seenIds.add(currentId);
              previousNodeIds.set(pos, currentId);
            }
          });

          if (requiresUpdate) {
            view.dispatch(tr);
          }

          return {};
        },

        // Process transactions to assign IDs only to new nodes
        appendTransaction: (transactions, oldState, newState) => {
          if (!transactions.some((tr) => tr.docChanged)) return null;

          const currentIds = new Set<string>();

          const newNodeIds = new Map();

          // First pass: collect all existing IDs and detect duplicates
          newState.doc.descendants((node, pos) => {
            if (!nodeTypes.includes(node.type.name)) return;

            if (node.attrs.id) {
              currentIds.add(node.attrs.id);
              newNodeIds.set(pos, node.attrs.id);
            }
          });

          const tr = newState.tr;
          let modified = false;

          // Second pass: assign IDs to nodes that need them
          newState.doc.descendants((node, pos) => {
            if (!nodeTypes.includes(node.type.name)) return;

            let needsNewId = false;

            if (!node.attrs.id) {
              needsNewId = true;
            } else {
              // Find all nodes with this same ID in the document
              const nodesWithSameId: { node: Node; pos: number }[] = [];
              newState.doc.descendants((otherNode, otherPos) => {
                if (
                  nodeTypes.includes(otherNode.type.name) &&
                  otherNode.attrs.id === node.attrs.id
                ) {
                  nodesWithSameId.push({ node: otherNode, pos: otherPos });
                }
              });

              if (nodesWithSameId.length > 1) {
                // Keep ID only for the position that existed in previous state with this ID
                let foundOriginal = false;
                for (const item of nodesWithSameId) {
                  if (previousNodeIds.get(item.pos) === node.attrs.id) {
                    foundOriginal = true;
                    break;
                  }
                }

                // If we haven't found the original OR this isn't the original, we need a new ID
                if (
                  !foundOriginal ||
                  previousNodeIds.get(pos) !== node.attrs.id
                ) {
                  needsNewId = true;
                }
              }
            }

            if (needsNewId) {
              const newId = generateUniqueId(currentIds);

              tr.setNodeMarkup(pos, null, { ...node.attrs, id: newId });
              currentIds.add(newId);
              newNodeIds.set(pos, newId);
              modified = true;
            }
          });

          previousNodeIds = newNodeIds;

          return modified ? tr : null;
        },
      }),

      new Plugin({
        key: new PluginKey('nodeIDPasteHandler'),

        props: {
          handlePaste: (view, event, slice) => {
            const newSlice = new Slice(
              stripDataIdFromFragment(slice.content),
              slice.openStart,
              slice.openEnd
            );

            const { state } = view;
            const tr = state.tr.replaceSelection(newSlice);
            view.dispatch(tr.setMeta('paste', true));

            return true;
          },
        },
      }),
    ];
  },
});

/**
 * Recursively process a fragment, removing id attributes from nodes.
 */
function stripDataIdFromFragment(fragment: Fragment): Fragment {
  const newContent: any[] = [];

  fragment.forEach((node) => {
    if (node.isText) {
      newContent.push(node);
      return;
    }

    const newAttrs = { ...node.attrs };

    if ('id' in newAttrs) {
      delete newAttrs.id;
    }

    if (node.content && node.content.size > 0) {
      const newNode = node.type.create(
        newAttrs,
        stripDataIdFromFragment(node.content),
        node.marks
      );
      newContent.push(newNode);
    } else {
      const newNode = node.type.create(newAttrs, null, node.marks);
      newContent.push(newNode);
    }
  });

  return Fragment.from(newContent);
}

function filterUnsupportedNodes(name: string) {
  return !unsupportedNodeTypes.includes(name);
}

function generateUniqueId(seenIds: Set<string>) {
  let newId;

  do {
    newId = uuidv4();
  } while (seenIds.has(newId));

  return newId;
}
